import WebServiceWrapper from "./WebServiceWrapper";

export const CenterService = {
  GetAllCenters,
  GetCentersExceptAdminSite,
  SaveCenter,
  DeleteCenter,
  GetPatientsCenterSummary
};

function GetAllCenters() {
  return WebServiceWrapper.Get(`/center/GetAllCenters`);
}
function GetCentersExceptAdminSite() {
  return WebServiceWrapper.Get(`/center/GetCentersExceptAdminSite`);
}
function SaveCenter(payload) {
  return WebServiceWrapper.Post("/center/SaveCenter", payload);
}
function DeleteCenter(centerId) {
  return WebServiceWrapper.Get(`/center/DeleteCenter?centerId=${centerId}`);
}
function GetPatientsCenterSummary(roleId, centerId) {
  return WebServiceWrapper.Get(`/center/GetPatientSummary?roleId=${roleId}&centerId=${centerId}`);
}