import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const DateControl = ({
  hideDay,
  hideMonth,
  marginLeft = true,
  ctrlId,
  HandleDateChange,
  optionId,
  defDate,
  defMonth,
  defYear,
  date,
}) => {
  const defaultDate = "MM/DD/YYYY";
  const defaultMonth = "MM";
  const defaultYear = "YYYY";
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
  const [selectedYear, setSelectedYear] = useState(defaultYear);

  const yearArray = [];
  const monthArray = Array.from({ length: 12 }, (_, index) =>
    index < 9 ? `0${index + 1}` : index + 1
  );
  const today = new Date();

  for (let i = today.getFullYear(); i >= 1920; i--) {
    yearArray.push(i);
  }

  const preventManualInput = (e) => {
    e.preventDefault();
  };
  let inputProps = {
    readOnly: true,
    onBeforeInput: preventManualInput,
    value: date != null && date != "" ? date : selectedDate,
    style: {
      width: "130px",
      marginRight: "5px",
      height: "25px",
      marginLeft: marginLeft === true ? "5px" : "0",
    },
  };
  const HandleFullDateChange = (e) => {
    if (e.isValid()) {
      setSelectedDate(e.format("MM/DD/yyyy"));
      HandleDateChange(ctrlId, e.format("MM/DD/yyyy"), optionId);
    }
  };
  const HandleYearSelection = (e) => {
    const year = e.target.textContent;
    setSelectedYear(year);
    HandleDateChange(ctrlId, year, optionId, 2); //year selected send 2
  };
  const HandleMonthSelection = (e) => {
    const month = e.target.textContent;
    setSelectedMonth(month);
    HandleDateChange(ctrlId, month, optionId, 1); //month selected send 1
  };
  /* <summary>
  date: 13-10-2023
  Name: AP
  description: Set date to defaut value
  <summary>*/
  useEffect(() => {
    if (defDate) setSelectedDate(defaultDate);
    if (defMonth) setSelectedMonth(defaultMonth);
    if (defYear) setSelectedYear(defaultYear);
  }, [defDate, defMonth, defYear]);

  /* <summary>
  date: 31-10-2023
  Name: AP
  description: Set the min and max dates for the calendar
  <summary>*/
  const SetDateRange = (current) => {
    return current.isSameOrAfter("01/01/1920");//&& current.isSameOrBefore("12/01/2023");
  }
  return (
    <>
      {!hideDay && !hideMonth && (
        <Datetime
          inputProps={inputProps}
          dateFormat="MM/DD/yyyy"
          timeFormat={false}
          className="datepicker"
          onChange={HandleFullDateChange}
          closeOnSelect="true"
          isValidDate={SetDateRange}
        //wrapperClassName="datePickerCalendar"
        />
      )}
      {hideDay && !hideMonth && (
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className="custom-dropdown-button"
          >
            {date != null && date !== "" && (`${date}`?.split('/')[0] ?? "") !== "" ? `${date}`?.split('/')[0] : selectedMonth}
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-dropdown-menu">
            {monthArray?.map((m, i) => (
              <Dropdown.Item
                as="button"
                key={i + 1}
                className="custom-dropdown-item"
                style={{ width: "90px" }}
                onClick={HandleMonthSelection}
                closeonselect="true"
              >
                {m}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {hideDay && (
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className="custom-dropdown-button"
          >
            {date != null && date !== "" && (`${date}`?.split('/').pop() ?? "") !== "" ? `${date}`?.split('/').pop() : selectedYear}
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-dropdown-menu">
            {yearArray?.map((y, i) => (
              <Dropdown.Item
                as="button"
                key={i}
                className="custom-dropdown-item"
                style={{ width: "90px" }}
                onClick={HandleYearSelection}
                closeonselect="true"
              >
                {y}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default DateControl;
