import WebServiceWrapper from "./WebServiceWrapper";

export const AvatreatmentService = {
    GetAvatreatmentData,
    SaveAvatreatmentData
};

function GetAvatreatmentData(patientid) {
    debugger;
  return WebServiceWrapper.Get(`/avatreatment/GetAvatreatmentData?patientid=${patientid}`);
}
function SaveAvatreatmentData(data){
    debugger;
  return  WebServiceWrapper.Post(`/avatreatment/SaveAvatreatmentData`,data);
}