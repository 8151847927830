import React, { useContext, useEffect, useState } from 'react'
import { CenterService } from '../WebApiServices/Center.service';
import { AppContext } from '../Contexts/AppContextProvider';
import { DefaultAGGridProps, ErrorMessages, HTTPResponse, ToastMessageType } from '../Helpers/Enums';
import { GetLocalStorageData } from '../Helpers/Utilities';
import { AgGridReact } from 'ag-grid-react';


const PatientSummary = ({ CloseModal }) => {
    const [summary, setSummary] = useState([]);
    const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext);

    let userDetails = GetLocalStorageData("user")
    userDetails = userDetails && JSON.parse(userDetails);


    const roleId = userDetails?.userRole ?? 0;//GetLocalStorageData("userRole");
    const centerId = userDetails?.centerId ?? 0;//GetLocalStorageData("centerId") ?? 0;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const FetchData = async () => {

            try {
                ToggleLoader(true);
                const response = await CenterService.GetPatientsCenterSummary(roleId, centerId)
                ToggleLoader(false);
                const { status } = response;
                if (status === HTTPResponse.Unauthorized) {
                    HandleSessionTimeout();
                }
                else if (status === HTTPResponse.OK) {
                    setSummary(response?.data ?? []);
                }
                else
                    throw response.error;
                setLoading(false);
            } catch (error) {
                setLoading(false);
                ShowToast(ErrorMessages.LOAD_ERROR, ToastMessageType.Failed);
            }
        }
        FetchData();
    }, [])


    const CloseModalPopUp = () => {
        CloseModal();
    }


    // GRID

    // const iconStyle = {
    //     width: 25,
    //     margin: 10,
    //     cursor: "pointer",
    //   };
    const columnDefs = [
        {
            headerName: "Name",
            field: "name",
            width: 255,
            //style={iconStyle}
            cellRenderer: (params) => <div>
                {params.data.name} </div>,
        },
        {
            headerName: "Total",
            field: "total",
            width: 120,
            cellRenderer: (params) => <div>
                {params.data.total} </div>,
        },
        {
            headerName: "Saved As Complete",
            field: "savedAsComplete",
            width: 180,
            cellRenderer: (params) => <div>
                {params.data.savedAsComplete} </div>,
        },
        {
            headerName: "Exported",
            field: "exported",
            width: 120,
            cellRenderer: (params) => <div>
                {params.data.exported} </div>,
        },
        {
            headerName: "Ineligible",
            field: "ineligible",
            width: 122,
            cellRenderer: (params) => <div>
                {params.data.ineligible} </div>,
        },
    ];
    const defaultColDef = {
        resizable: true,
        sortable: true,
        cellClass: "td no-border",
    };
    const gridOptions = {
        columnDefs,
        defaultColDef,
        rowHeight: DefaultAGGridProps.ROW_HEIGHT,
        pagination: true,
        paginationPageSize: DefaultAGGridProps.ROWS_PER_PAGE,
    };

    const GetTableHeight = () => {
        return 100 + 50 * 4;
    };
    const agGridLocaleText = {
        noRowsToShow: DefaultAGGridProps.NOT_FOUND,
    };

    return (
        <>{!loading &&
            <section className="edit-modal">
                <div
                    className="modal"
                    id="CenterPatientsSummary"
                    tabIndex={-1}
                    aria-labelledby="ModalFormLabel"
                    aria-hidden="true"
                    style={{ display: "block", paddingRight: "17px", backgroundColor: "#00000094" }}
                >
                    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "55%", }}>
                        <div className="modal-content" >
                            <div className="modal-body">
                                <div className="container" style={{ height: "345px", }}>
                                    <div className="row modal-headerr">
                                        <div className="col-10 col-md-10 col-lg-10">
                                            <label htmlFor="">
                                                <h5>Summary</h5>
                                            </label>
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-2 btn-align">
                                            <button
                                                type="button"
                                                className="btn-close btn-close-white"
                                                data-bs-dismiss="modal"
                                                aria-label="Close" onClick={CloseModalPopUp}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            className="ag-theme-alpine "
                                            style={{
                                                height: summary?.length === 0 ? 300 : GetTableHeight(), //DefaultAGGridProps.TABLE_HEIGHT,
                                                width: "100%", border: "1px solid black"
                                            }}
                                        >
                                            <AgGridReact
                                                rowData={summary}
                                                gridOptions={{ ...gridOptions, localeText: agGridLocaleText }}
                                                // onPaginationChanged={() => {
                                                //     window.scrollTo(0, 0);
                                                // }}
                                                pagination={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >}
        </>)
}

export default PatientSummary