import WebServiceWrapper from "./WebServiceWrapper";

export const PatientDemographicsService = {
  GetAllDemographics,
  SaveDemographic,
  GetUSStates,
};

function GetAllDemographics(patientId) {
  return WebServiceWrapper.Get(`/demographic/GetAllDemographicsData?patientId=${patientId}`);
}

function SaveDemographic(payload) {
  return WebServiceWrapper.PostWithHeader("/demographic/SaveDemographicData", payload);
}

function GetUSStates() {
  return WebServiceWrapper.Get(`/demographic/GetAllUSStates`);
}