import CryptoJS, { format } from "crypto-js";
import { ArchiveService } from "../WebApiServices/Archive.service";
import ArchiveModel from "../DataModels/ArchiveModel";
const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);


/* <summary>
date: 31-07-2023
Name: GM
description: 
<summary>*/
export const GetLoggedInUserID = () => {
  const userId = GetLocalStorageData("userId");
  return Number(userId);
};

/* <summary>
date: 31-07-2023
Name: GM
description: Add data to local storage in encrypted form
<summary>*/
export const AddLocalStorageData = (storageKey, value) => {

  const encryptedValue = EncryptUsingAES256(JSON.stringify(value));
  const encryptedKey = EncryptUsingAES256(storageKey);
  if (localStorage.getItem(storageKey)) {
    localStorage.removeItem(storageKey);
  }
  localStorage.setItem(encryptedKey, encryptedValue);
};

/* <summary>
  date: 31-07-2023
  Name: GM
  description: get decrypted data from local storage
  <summary>*/
export const GetLocalStorageData = (storageKey) => {

  const encryptedKey = EncryptUsingAES256(storageKey);
  const encryptedValue = localStorage.getItem(encryptedKey) ?? undefined;
  try {
    if (encryptedValue) {
      return JSON.parse(DecryptUsingAES256(encryptedValue));
    }
  } catch (error) { }
  return encryptedValue;
};

/* <summary>
  date: 11-04-2023
  Name: GM
  description: get decrypted data from local storage
  <summary>*/
export const RemoveLocalStorageData = (storageKey) => {
  const encryptedKey = EncryptUsingAES256(storageKey);
  localStorage.removeItem(encryptedKey);
};

/* <summary>
date: 31-07-2023
Name: GM
description: Check if string is empty/undefined/null
<summary>*/
export const isStringEmpty = (text) => {

  try {
    return !text || text.trim().length === 0;
  } catch (error) {
    console.log(error)
  }
};

/* <summary>
date: 31-07-2023
Name: GM
description: Encryption using CryptoJS
<summary>*/
export const EncryptUsingAES256 = (text) => {
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
    iv,
    keySize: 128 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

/* <summary>
  date: 31-047-2023
  Name: GM
  description: Decryption using CryptoJS
  <summary>*/
export const DecryptUsingAES256 = (decString) => {
  const decrypted = CryptoJS.AES.decrypt(decString, key, {
    iv,
    keySize: 128 / 8,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};
/* <summary>
date: 10-08-2023
Name: AP
description: Email Validation
<summary>*/
export const IsEmailValid = (emailAddress) => {
  const emailRegexString =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  ///^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/; from other ag projects
  return emailRegexString.test(emailAddress);
};

/* <summary>
date: 05-09-2023
Name: AP
description: Validates password
<summary>*/
export const IsPasswordValid = (passowrd) => {
  const passwordRegexString = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
  return passwordRegexString.test(passowrd);
};
/* <summary>
date: 13-10-2023
Name: AP
description: Common fn calling archive service to add archive 
<summary>*/
export const AddArchiveFunction = (actionNumber, data, userId, secondaryId) => {
  const payLoad = new ArchiveModel();
  payLoad.archiveaction = actionNumber;
  payLoad.archivedata = data;
  payLoad.userid = userId;
  payLoad.secondaryid = secondaryId;
  //payLoad.CreatedBy = userId;

  ArchiveService.AddArchive(payLoad);
};
/* <summary>
date: 13-10-2023
Name: AP
description: Check if date is before 1920
<summary>*/
export const IsDateBefore1920 = (dtdate) => {
  const date1920 = new Date("1920/01/01");
  return FormatDate(dtdate) < FormatDate(date1920);
};
/* <summary>
date: 13-10-2023
Name: AP
description: Format date for calculation
<summary>*/
export const FormatDate = (date) => {
  date = new Date(date);
  if (date instanceof Date && !isNaN(date)) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return new Date(`${year}-${month}-${day}`);
  } else return false;
};
/* <summary>
date: 20-10-2023
Name: AP
description: Format date to display
<summary>*/
export const DisplayFormat = (date) => {

  if (date instanceof Date && !isNaN(date)) {
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-11) and add 1, then pad with '0'
    const day = String(date.getDate()).padStart(2, '0'); // Get the day of the month and pad with '0'
    const year = date.getFullYear(); // Get the year

    return `${month}/${day}/${year}`;
  } else return false
}
/* <summary>
date: 20-10-2023
Name: AP
description: Subtract given months from given
<summary>*/
export const SubtractMonths = (date, months) => {
  if (date instanceof Date && !isNaN(date)) {
    date.setMonth(date.getMonth() - months);
  }

  return date;
}
/* <summary>
date: 26-10-2023
Name: AP
description: Subtract given months from given
<summary>*/
export const AddMonths = (date, months) => {

  if (date instanceof Date && !isNaN(date)) {
    date.setMonth(date.getMonth() + months);
  }

  return date;
}

/* <summary>
date: 13-10-2023
Name: AP
description: Allow only positive integers
<summary>*/
export const AllowOnlyIntegers = (value) => {
  const NUMBER_REGEX = /^[0-9\b]+$/;
  // if value is not blank, then test the regex
  return NUMBER_REGEX.test(value)
}
export const AllowPositiveDecimalNumbers = (value) => {

  const NUMBER_REGEX = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/
  // if value is not blank, then test the regex
  return NUMBER_REGEX.test(value)
}

/* <summary>
date: 26-10-2023
Name: RM
description: Get minimun date from the list of dates
<summary>*/
export const GetMininumDate = (listOfDates) => {
  let dates = listOfDates;
  let arrOfDates = [];
  let minDate = "";
  let date;
  for (let i = 0; i < dates.length; i++) {
    if (dates[i] != null)
      arrOfDates.push(new Date(dates[i]));
  }
  minDate = new Date(Math.min(...arrOfDates));
  return minDate.toString();
}
/* <summary>
date: 01-11-2023
Name: AP
description: Checks if given date is beyond current date
<summary>*/
export const IsDateAfterToday = (date) => {
  const currentDate = new Date();
  if (date instanceof Date && !isNaN(date))
    return date > currentDate;
  return false;
}
/* <summary>
date: 21-11-2023
Name: AP
description: Generates Random Alphabets depending on their num value
<summary>*/
export const GetRandomAlphaNumber = () => {
  debugger
  const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const randomIndex = Math.floor(Math.random() * alphabets.length);
  const letter = alphabets[randomIndex];
  return letter;
}