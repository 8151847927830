import WebServiceWrapper from "./WebServiceWrapper";

export const HealthcareService = {
    GetHealthcareData,
    SaveHealthcareData
};

function GetHealthcareData(patientid) {
    return WebServiceWrapper.Get(`/healthcare/GetHealthcareData?patientid=${patientid}`);
}
function SaveHealthcareData(data) {
    return WebServiceWrapper.Post(`/healthcare/SaveHealthcareData`, data);
}