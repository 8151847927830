const ErrorField = ({
    show = true,
    message,
    style = { color: "red", fontSize: "14px", paddingLeft: "15px"},
}) => {
    if (!show) {
        return <></>;
    }
    return (
        <div className="error-input">
            <span style={style}>{message} </span>
        </div>
    );
};
export default ErrorField;

