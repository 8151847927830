import React from 'react'
import { isStringEmpty } from '../Helpers/Utilities'
import { CommonMsgs } from '../Helpers/HelperText'

const ConfirmPopUp = ({ id, YesClick, NoClick, title }) => {
  const OnYesClick = () => {
    debugger
    YesClick(id)
  }
  const OnNoClick = () => {
    NoClick()
  }
  return (
    <>
      {/* Delete Modal */}
      <section className="delete-modal">
        <div
          className="modal"
          id="deleteAbstractorModal"
          tabIndex={-1}
          aria-labelledby="ModalFormLabel"
          aria-hidden="true"
          style={{ display: "block", paddingRight: "17px", backgroundColor: "#00000094" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="container">
                  <div className="delete-modal-main">
                    <div className="text-input-main">
                      <div>
                        <p>{isStringEmpty(title) ? CommonMsgs.DeleteConfirmMsg : title}</p>
                      </div>
                      <div className="confirm-btn-div">
                        <button
                          className="modal-close-btn"
                          //data-bs-dismiss="modal"
                          onClick={OnNoClick}
                        >
                          No
                        </button>
                        <button className="modal-yes-btn"
                          onClick={OnYesClick}>
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ConfirmPopUp