import React from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { GetLoggedInUserID } from "../Helpers/Utilities";

const PrivateRoute = () => {
  const outlet = useOutlet();
  return GetLoggedInUserID() ? outlet : <Navigate to="/" />;
};

export default PrivateRoute;
